<template>
  <div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mb-4">
      <div class="card-body px-lg-5 py-lg-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-control-label">Title</label>
            <span class="ml-1 text-danger">*</span>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the title"
              type="text"
              v-model="data.title"
            />
            <label
              v-for="error in errors.title"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="form-control-label">Link label</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the link label"
              type="text"
              v-model="data.link_label"
            />
            <label
              v-for="error in errors.link_label"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Link url</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the link url"
              type="text"
              v-model="data.link_url"
            />
            <label
              v-for="error in errors.link_url"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <label class="form-control-label">Content</label>
            <span class="ml-1 text-danger">*</span>
            <rich-text id="content" @change="handle_content" :text="content" />
            <label
              v-for="error in errors.content"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-secondary px-5" @click="this.$router.go(-1)">
              Cancel
            </button>
            <button
              class="btn btn-md btn-default my-4"
              @click="update_resource"
            >
              Update Resource
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RichText from "../../components/RichTextEditor.vue";
import axios from "axios";
import { alert } from "../../utils/alertUtils";

export default {
  name: "NewResource",
  components: {
    "rich-text": RichText,
  },
  data() {
    return {
      data: {},
      content: "",
      errors: {
        description: [],
      },
    };
  },
  methods: {
    update_resource: function () {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}`;
      axios
        .put(url, this.data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("Resource Updated successfully.");
          this.$router.push({ name: "Resources" });
        })
        .catch((err) => {
          console.log(err.response);
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
    handle_content: function (content) {
      this.data.content = content;
    },
    get_resource: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          this.data.title = res.data.title;
          this.data.content = res.data.content;
          this.data.link_label = res.data.link_label;
          this.data.link_url = res.data.link_url;
          this.content = res.data.content;
        })
        .catch(() => {
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
  },
  created() {
    this.get_resource();
  },
};
</script>
